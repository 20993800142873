.memberroot {
    width: 40vh;
    height: 70vh;
    background: rgba(0,0,0,0.5);
    margin: 10px;
    border-radius: 0;
  }

.membermedia {
    width: 40vh;
    height: 40vh;
    border: 0;
  }

.name {
    font-weight: bold;
    font-size: 2.5vh;
    color: #fff;
    text-align: center;
  }

.title {
    font-weight: bold;
    font-size: 1.8vh;
    color: #fff;
    text-align: center;
  }


.desc {
    font-size: 1.8vh;
    color: #ddd;
  }

.cardContent {
    padding-top: 0;
    padding-bottom: 0;
}


.cardimage {
  width : 40vh;
  height: auto; /*to preserve the aspect ratio of the image*/
}