.teamroot {
    min-height: 100vh;
    max-height: 320vh;
    justify-content: center;
    align-items: center;
    background-color: #00AB2C;
}
.textRootGreen {
    display: flex;
    justify-content:  center;
    align-items:  center;
    height: 80vh;
    background-color: #00AB2C;
}


.members {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

.team {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    font-size: 1.5rem;
}

.downButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B9DEC1;
    font-size: 1.5rem;
}

.goDown {
    color: #fff;
    font-size: 2rem;
}