.videowrapperroot {
    width: 60vh;
    background: rgba(0,0,0,0.5);
    margin: 10px;
    border-radius: 0;
  }

.videowrappermedia {
    border: 0;
    height: 34vh;
    width: 100%;
  }

.title {
    font-weight: bold;
    font-size: calc((.4em + 2vmin) + (.4em + 2vmax));
    color: #fff;
    text-align: center;
  }

.cardContent {
    padding-top: 0;
    padding-bottom: 0;
}