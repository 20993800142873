.textRootWhite {
    display: flex;
    justify-content:  center;
    align-items:  center;
    height: 100%;
    min-height: 100vh;
    background-color: #fff;
}

.textRootGreen {
    display: flex;
    justify-content:  center;
    align-items:  center;
    height: 100%;
    min-height: 100vh;
    background-color: #00AB2C;
}

.colorTextGreen {
    color: #00AB2C;
}
.colorTextWhite {
    color: #fff;
}

.header {
    text-align: center;
    font-size: calc((.4em + 1.5vmin) + (.4em + 1.5vmax));
    font-family: sans-serif;
    font-weight: bold
}

.greenText {
    color: #00AB2C;
    font-size: calc((.2em + 0.7vmin) + (.2em + 0.7vmax));
    text-align: center;
}

.whiteText {
    color: #fff;
    font-size: calc((.2em + 0.7vmin) + (.2em + 0.7vmax));
    text-align: center;
}

.explanation {
    color: #00AB2C;
    font-size: 1.5em;
    text-align: center;
}
.card {
    max-width: 100vh;
    background: none;
    margin: 20px;
    flex-direction: row;
    box-shadow: none !important;
}
.desc {
    font-family: sans-serif;
    font-size: 1.5em;
    color: #00AB2C;
    text-align: center;
}
.descText {
    font-family: sans-serif;
    font-size: 1.5em;
    color: #00AB2C;
    text-align: center;
}

.listText {
    font-family: sans-serif;
    font-size: 1.5em;
    color: #fff;
    text-align: left;
}

.downButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B9DEC1;
    font-size: 1.5em;
}

.goDownWhite {
    color: #fff;
    font-size: 1.5em;
}

.goDownGreen {
    color: #00AB2C;
    font-size: 1.5em;
}