.presentationsroot {
    justify-content: center;
    align-items: center;
    background-color: #242424;
}

.textStyle {
    display: flex;
    justify-content:  center;
    align-items:  center;
}

.videos {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: row;
}

.downButton {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #B9DEC1;
    font-size: 1.5rem;
}

.goDown {
    color: #fff;
    font-size: 2rem;
}
.descText {
    font-family: Nunito;
    font-size: 1.3rem;
    color: #fff;
    text-align: center;
}
.card {
    max-width: 100vh;
    background: none;
    margin: 20px;
    flex-direction: row;
    box-shadow: none !important;
}